.services_container {

    margin: 20% auto;
    padding: 20px;
    position: relative;
    z-index: 10;
}
.services_container::before {
    content: "";
    height: 2px;
    width: 100%;
    background-color: black;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: block;
    margin-bottom: 2%;
}
.services_container::after {
    content: "";
    height: 2px;
    width: 100%;
    background-color: black;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: block;
    margin-top: 2%;
}
.serv_flex_container {
    display: flex;
    justify-content: center; 
}
.serv_flex_item0, .serv_flex_item1, .serv_flex_item2, .serv_flex_item3 {
    font-size: 150%;
    display: flex;
    flex-direction: column;
    flex-basis: 400px;
    font-family: Roboto;
    font-weight: 700;
    margin: 50px 0;
}
.services_text {
    font-family: Roboto;
    font-weight: 700;
}


#mglass {
    bottom: -12%;
    left: -100px;
    position: absolute;
}

@media (max-width: 640px) {
    #mglass {
        bottom: 45%;
        left: 10%;
        position: absolute;
    }

     .serv_flex_item1, .serv_flex_item2, .serv_flex_item3 {
        font-size: .9rem;
        padding-top: 80%;
    }
    .serv_flex_item0 {
        flex-basis: 0;
    }
    .services_text {
        font-size: .8rem;
    }
    .services_container {
        margin: 0 !important;
        width: 100% !important;
    }
}
